<script setup lang="ts">
const { t } = useT();
</script>

<template>
	<NuxtLink to="/scratch-cards/" class="banner">
		<NuxtImg
			src="/nuxt-img/sidebar/scratch-cards.png"
			alt="tao-scratch"
			format="avif"
			width="60"
			height="50"
			loading="lazy"
		/>
		<AText type="small" :modifiers="['uppercase', 'bold']">
			<span>{{ t("Super Scratchers") }}</span>
		</AText>
	</NuxtLink>
</template>
